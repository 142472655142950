import { people01, people02, people03, facebook, instagram, linkedin, twitter, airbnb, binance, coinbase, dropbox, send, shield, star, Alovie, alove, quantum, techlit, arrietty  } from "../assets";

export const navLinks = [
  {
    id: "home",
    title: "Home",
  },
  {
    id: "features",
    title: "Features",
  },
  {
    id: "product",
    title: "Product",
  },
  {
    id: "clients",
    title: "Clients",
  },
  {
    id: "contact",
    title: "Contact Us", // new link
  },
  {
    id: "plans",
    title: "Plans", // new link
  },
  {
    id: "case-study",
    title: "Case Studies"
  }
];

export const features = [
  {
    id: "feature-1",
    icon: star,
    title: "Smart Automation",
    content:
      "Experience unparalleled efficiency with our advanced AI-driven automation solutions.",
  },
  {
    id: "feature-2",
    icon: shield,
    title: "Fully Secured",
    content:
      "We implement top-tier security measures to ensure your data and processes remain safe.",
  },
  {
    id: "feature-3",
    icon: send,
    title: "Seamless Integrationr",
    content:
      "Our AI automation integrates effortlessly with your existing systems, enhancing productivity.",
  },
];

export const feedback = [
  {
    id: "feedback-1",
    content: "SoloScale Solutions modernized our website and operations, generating quality leads and easing our customer service burden with their AI agent. Their responsive team quickly adapts to our needs, allowing us to focus on priority tasks.",
    name: "Shekar C.",
    title: "CTO, Alovie Technologies",
    img: alove,
  },
  {
    id: "feedback-2",
    content: "SoloScale did an excellent job at creating and optimizing our company chatbot at Quantum Automation. Their team was always readily available for our needs and performed at an optimal level." ,
    name: "Syed H.",
    title: "CEO, Quantum AI ",
    img: quantum,
  },
  {
    id: "feedback-3",
    content: "Thanks to SoloScale’s AI-driven automation, our customer service efficiency has soared. Their solutions freed up our team to focus on higher priorities, enhancing our productivity." + "\u00A0".repeat(200),
    name: "Chandra C.",
    title: "CEO, Arrietty Solutions",
    img: arrietty,
  },

];

export const stats = [
  {
    id: "stats-1",
    title: "Active Users",
    value: "5000+",
  },
  {
    id: "stats-2",
    title: "Trusted by Businesses",
    value: "300+",
  },
  {
    id: "stats-3",
    title: "Automation Processes",
    value: "1M+",
  },
];

export const footerLinks = [
  {
    title: "Useful Links",
    links: [
      {
        name: "Services",
        link: "/",
      },
      {
        name: "Capabilities",
        link: "/",
      },
      {
        name: "Case Studies",
        link: "/",
      },
      {
        name: "Explore",
        link: "/",
      },
      {
        name: "Terms & Services",
        link: "/",
      },
    ],
  },
  {
    title: "Community",
    links: [
      {
        name: "Help Center",
        link: "/",
      },
      {
        name: "Partners",
        link: "/",
      },
      {
        name: "Suggestions",
        link: "/",
      },
      {
        name: "Blog",
        link: "/",
      },
      {
        name: "Newsletters",
        link: "/",
      },
    ],
  },
  {
    title: "Partner",
    links: [
      {
        name: "Our Partner",
        link: "/",
      },
      {
        name: "Become a Partner",
        link: "/",
      },
    ],
  },
];

export const socialMedia = [
  // {
  //   id: "social-media-1",
  //   icon: instagram,
  //   link: "https://www.instagram.com/soloscale.solutions/",
  // },

];

export const clients = [
  {
    id: "client-1",
    logo: airbnb,
  },
  {
    id: "client-2",
    logo: binance,
  },
  {
    id: "client-3",
    logo: coinbase,
  },
  {
    id: "client-4",
    logo: dropbox,
  },
];