import styles from "../style";
import alovieImage from "../assets/Alovie Technologies Case Study.png"; // Import the image directly

const scrollToCalendly = () => {
  const calendlySection = document.getElementById('get-started');
  if (calendlySection) {
    calendlySection.scrollIntoView({ behavior: 'smooth' }); // Smooth scrolling to the section
  }
};

const CaseStudy = () => (
  <section className="flex flex-col items-center justify-center bg-[rgb(1,4,14)] min-h-screen py-16 sm:py-20 mt-20">
    {/* Title Section */}
    <div className="text-center mb-8 px-4">
      <h2 className="text-3xl sm:text-5xl font-bold text-white mb-4 sm:mb-6">
        AI Solutions: Alovie Technologies Case Study
      </h2>
      <p className="text-lg sm:text-2xl text-gray-200 max-w-xl sm:max-w-5xl mx-auto leading-relaxed">
        Learn how SoloScale transformed customer service and lead generation for Alovie Technologies through cutting-edge AI-driven automation.
      </p>
    </div>

    {/* Image Section */}
    <div className="relative bg-gradient-to-b from-[#1e40af] to-[#14101d] rounded-lg shadow-lg overflow-hidden w-full max-w-full sm:max-w-7xl p-4 sm:p-6">
      <img
        src={alovieImage}
        alt="Alovie Case Study"
        className="w-full h-auto object-contain rounded-lg"
      />
    </div>

    {/* Call to Action Button */}
    <div className="mt-8 px-4">
      <button 
        type="button" 
        className="py-3 px-5 sm:py-4 sm:px-6 font-poppins font-medium text-[16px] sm:text-[18px] text-gray-900 bg-gradient-to-r from-yellow-300 to-yellow-500 rounded-[10px] outline-none transform transition-all duration-500 ease-in-out hover:bg-yellow-600 hover:scale-105"
        onClick={scrollToCalendly}
      >
        See How We Can Help You
      </button>
    </div>
  </section>
);

export default CaseStudy;
