import { useState, useEffect } from "react";
import { close, logo, menu, soloscalel } from "../assets";
import { useNavigate } from "react-router-dom"; // Import useNavigate from React Router

const navLinks = [
  { id: "home", title: "Home" },
  { id: "process", title: "Our Process" },
  { id: "services", title: "Services" },
  { id: "partners", title: "Partners" },
  { id: "testimonials", title: "Testimonials" },
  { id: "get-started", title: "Get Started" },
  { id: "contact", title: "Contact Us" },
  { id: "case-study", title: "Case Study", external: true, path: "/case-study" }
];

const Navbar = () => {
  const [active, setActive] = useState("Home");
  const [toggle, setToggle] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);
  const navigate = useNavigate(); // Initialize navigate for programmatic navigation

  useEffect(() => {
    const handleScroll = () => {
      setIsScrolled(window.scrollY > 0);

      const sections = document.querySelectorAll("section");
      const scrollPosition = window.pageYOffset + 100;

      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          setActive(section.getAttribute("id"));
        }
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleNavigation = (nav) => {
    if (nav.external) {
      // Navigate to external path like /case-study
      navigate(nav.path);
    } else {
      // Navigate locally using hash navigation
      const section = document.getElementById(nav.id);
      if (section) {
        window.scrollTo({
          top: section.offsetTop - 100,
          behavior: "smooth"
        });
      } else {
        // If navigating from case-study back to the main page
        window.location.href = `/#${nav.id}`;
      }
    }
    setActive(nav.title);
    setToggle(false);
  };

  return (
    <nav className={`w-full flex justify-between items-center fixed top-0 left-0 right-0 z-50 transition-all duration-300 ${
      isScrolled ? "bg-primary shadow-lg py-4" : "bg-transparent py-6"
    }`}>
      <div className="w-full flex justify-between items-center max-w-7xl mx-auto px-4">
        <img src={soloscalel} alt="Ai" className="w-[80px] h-[80px] rounded-full ml-6" />

        <ul className="list-none md:flex hidden justify-end items-center flex-1 mr-16">
          {navLinks.map((nav, index) => (
            <li
              key={nav.id}
              className={`font-poppins font-normal cursor-pointer text-[16px] ${
                active === nav.title ? "text-white" : "text-dimWhite"
              } ${index === navLinks.length - 1 ? "mr-0" : "mr-10"} hover:text-white transition-colors duration-300`}
              onClick={() => handleNavigation(nav)}
            >
              <a>{nav.title}</a>
            </li>
          ))}
        </ul>

        <div className="md:hidden flex flex-1 justify-end items-center mr-6">
          <img
            src={toggle ? close : menu}
            alt="menu"
            className="w-[28px] h-[28px] object-contain cursor-pointer"
            onClick={() => setToggle(!toggle)}
          />

          <div
            className={`${
              !toggle ? "hidden" : "flex"
            } p-6 bg-black-gradient absolute top-20 right-0 mx-4 my-2 min-w-[140px] rounded-xl sidebar`}
          >
            <ul className="list-none flex justify-end items-start flex-1 flex-col">
              {navLinks.map((nav, index) => (
                <li
                  key={nav.id}
                  className={`font-poppins font-medium cursor-pointer text-[16px] ${
                    active === nav.title ? "text-white" : "text-dimWhite"
                  } ${index === navLinks.length - 1 ? "mb-0" : "mb-4"} hover:text-white transition-colors duration-300`}
                  onClick={() => handleNavigation(nav)}
                >
                  <a>{nav.title}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
